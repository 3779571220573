// Handle system theme changes when in auto mode
if (document.documentElement.getAttribute('data-bs-theme') === 'auto') {
    const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

    const updateTheme = (e) => {
        if (e.matches) {
            document.documentElement.setAttribute('data-bs-theme', 'dark');
        } else {
            document.documentElement.setAttribute('data-bs-theme', 'light');
        }
    };

    // Listen for system theme changes
    darkModeMediaQuery.addListener(updateTheme);

    // Set initial theme
    updateTheme(darkModeMediaQuery);
}